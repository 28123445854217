import request from "@/utils/request";

export function getAddressList() {
  return request({
    url: '/app/mall/member/address/list',
    method: 'GET',
    needToken: true
  })
}

export function getInfo(id){
  return request({
    url: `/app/mall/member/address/${id}`,
    method: 'GET',
    needToken: true
  })
}

export function create(data){
  return request({
    url: '/app/mall/member/address',
    method: 'POST',
    needToken: true,
    data
  })
}

export function update(data) {
  return request({
    url: `/app/mall/member/address/${data.id}`,
    method: 'PUT',
    needToken: true,
    data
  })
}

export function updateDefaultMark(id){
  return request({
    url: `/app/mall/member/address/default/${id}`,
    method: 'PUT',
    needToken: true
  })
}