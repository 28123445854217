<template>
  <div class="bg-ligtGary">
    <TopNav/>

    <div class="header header-cart">
      <div class="w w1200">
        <div class="logo">
          <div class="logoImg">
            <router-link :to="{path:'/'}">
              <img :src="require('@/assets/co/' + co + '/logo.png')">
            </router-link>
          </div>
          <div class="tit">购物车（<em>{{sumNums}}</em>）</div>
        </div>
        <div class="dsc-search">
          <div class="form">
            <input autocomplete="off" v-model="keywords" type="text" class="search-text">
            <input type="hidden" name="store_search_cmt" value="0">
            <button type="submit" class="button button-icon" v-on:click="search"><i></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="w w1200" v-loading="cartLoading" element-loading-text="拼命加载中......">
        <div class="cart-empty" v-if="cartList.length <= 0">
          <div class="cart-message">
            <div class="txt">购物车快饿瘪了，主人快给我挑点宝贝吧</div>
            <div class="info">
              <router-link :to="{path:'/'}" class="btn sc-redBg-btn" tag="a">马上去逛逛</router-link>
            </div>
          </div>
        </div>
        <div class="cart-warp" v-else>
          <div class="cart-filter">
            <div class="cart-stepflex">
              <div class="cart-step-item curr">
                <span>1.我的购物车</span>
                <i class="iconfont icon-arrow-right-alt"></i>
              </div>
              <div class="cart-step-item">
                <span>2.填写订单信息</span>
                <i class="iconfont icon-arrow-right-alt"></i>
              </div>
              <div class="cart-step-item">
                <span>3.成功提交订单</span>
              </div>
            </div>
            <div class="sp-area store-selector">

            </div>
          </div>
          <div class="cart-table">
            <div class="cart-head">
              <div class="column c-checkbox">
                <div class="cart-checkbox cart-all-checkbox" >
                  <input type="checkbox" id="cart-selectall" class="ui-checkbox checkboxshopAll" v-on:click="setCheckAll($event)" v-model="checkAllChecked">
                  <label for="cart-selectall" class="ui-label-14" >全选</label>
                </div>
              </div>
              <div class="column c-goods">商品</div>
              <div class="column c-props"></div>
              <div class="column c-price">单价（元）</div>
              <div class="column c-quantity">数量</div>
              <div class="column c-sum">小计</div>
              <div class="column c-action">操作</div>
            </div>
            <div class="cart-tbody">
              <div class="cart-item" v-for="(good,index) in cartList" v-bind:key="good.cartId">
                <div class="item-list">
                  <div class="item-single">
                    <div class="item-item">
                      <div class="item-form">
                        <div class="cell s-checkbox">
                          <div class="cart-checkbox" ectype="ckList">
                            <input type="checkbox" :id="'checkItem_'+good.cartId" :value="index" v-model="goodsSelected"  name="checkItem" class="ui-checkbox">
                            <label :for="'checkItem_'+good.cartId" class="ui-label-14">&nbsp;</label>
                          </div>
                        </div>
                        <div class="cell s-goods">
                          <div class="goods-item">
                            <div class="p-img">
                              <router-link :to="{path:'goods',query:{id:good.goodsId}}" target="_blank" tag="a">
<!--                                <img :src="JSON.parse(good.images)[0].imageUrl" width="70" height="70">-->

                                <img :src="getCoverImage(good.images)" width="70" height="70">

                              </router-link>
                            </div>
                            <div class="item-msg">
                              <router-link :to="{path:'goods',query:{id:good.goodsId}}" target="_blank" tag="a">
                                {{good.goodsName}}
                              </router-link>
                              <div class="gds-types">

                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="cell s-props">
                          <p class="p-num" v-for="(para,index) in good.params" v-bind:key="index" style="margin-right: 5px">{{para.name}}：{{para.value}}</p>
                          <p class="p-num" v-if="good.params.length==0">&nbsp;</p>
                        </div>
                        <div class="cell s-price">
                          <strong><em>¥</em>{{good.price}}</strong>、
                          <div class="promotion-info" v-if="good.objectName">
                            <a href="javascript:void(0);" class="sales-promotion" ectype="c-promotion">{{good.objectName}}</a>
                          </div>
                        </div>
                        <div class="cell s-quantity">
                          <amount-box v-model="good.qty" @update="numChange(good)" :defaultVal="good.qty" :max="99999" :min="1"></amount-box>
                        </div>
                        <div class="cell s-sum">
                          <strong>
                            <font><em>¥</em>{{parseFloat(good.qty * good.price).toFixed(2)}}</font>
                          </strong>
                        </div>
                        <div class="cell s-action">
                          <a href="javascript:void(0);" class="cart-remove" v-on:click="deleteCart(good.cartId)">删除</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--
              <div class="ck-order-remark" style="background-color: #fff">
                <input type="text" v-model="memo" maxlength="45" size="15" class="text" placeholder="订单备注" autocomplete="off" style="color: rgb(204, 204, 204);">
                <span class="prompt">提示：请填写备注信息</span>
              </div>
              -->
            </div>

            <div class="cart-tfoot">
              <div class="cart-toolbar" ectype="tfoot-toolbar">
                <div class="w w1200">
                  <div class="cart-checkbox cart-all-checkbox" >
                    <input id="toggle-checkboxes-down" type="checkbox" class="ui-checkbox checkboxshopAll" v-on:click="setCheckAll($event)" v-model="checkAllChecked">
                    <label for="toggle-checkboxes-down" class="ui-label-14">全选</label>
                  </div>
                  <div class="operation">
                    <a class="cart-remove-batch" v-on:click="batDel" style="cursor:pointer">删除选中的商品</a>
                  </div>
                  <div class="toolbar-right">
                    <div class="comm-right">
                      <div class="btn-area">
                        <el-button class="submit-btn" @click.native="submitCart" :loading="submitLoading">确认订单</el-button>
                      </div>
                      <div class="price-sum" id="total_desc">
                        <span class="txt">总价：</span>
                        <span class="price sumPrice" id="cart_goods_amount" ectype="goods_total"><em>¥</em>{{sumPrice}}</span>
                      </div>
                      <div class="amount-sum">已选择<em class="cart_check_num" ectype="cartNum">{{sumNums}}</em>件商品</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w w1200">
        <guess-love cssName="p-panel-main c-history" :count="6"></guess-love>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/footer/Index";
import AmountBox from "@/components/AmountBox/Index";
import GuessLove from "@/components/GuessLove/Index";
import TopNav from "@/components/TopNav/Index";
import {getCartList} from "@/api/user";
import {getAddressList} from "@/api/address";
import {updateCartNum,cartDel} from "@/api/mall/cart";

export default {
  name: "Cart",
  data() {
    return {
      cartList:[],
      goodsSelected:[],
      sumNums: 0,
      sumPrice: 0,
      keywords: '',
      cartLoading:false,
      submitLoading:false,
      memo:'',  //备注

      checkAllChecked:true,

      consigneeInfo: null,
    }
  },
  components: {
    Footer,
    AmountBox,
    GuessLove,
    TopNav
  },
  created() {
    this.$store.commit('SET_CARTSELECTED', [])

    this.getCartList()
    this.getAddress()
  },
  watch: {
    cartList:{
      handler:function(){
        this.calSum()
      },
      deep:true//对象内部的属性监听，也叫深度监听
    },
    goodsSelected:{
      handler:function(){
        this.calSum()
      },
    },
  },
  methods:{
    getCartList(){
      this.cartLoading = true

      getCartList({}).then(res => {
        this.cartList = res.data.normal

        this.cartList.forEach((val, index)=>{
          if(val.paramsData!=null){
            val.params = JSON.parse(val.paramsData);
          }else{
            val.params = []
          }
          //默认全部选中
          this.goodsSelected.push(index)
        })

        // this.cartList.forEach((val,index)=>{
        //   if(val.products.images.indexOf("image.yabyy.com") == -1){
        //     val.products.images = settings.AdminUrl + val.products.images
        //   }
        //   if(val.parameters!=null){
        //     val.params = val.parameters.split('|')
        //   }else{
        //     val.params = []
        //   }
        //   //默认全部选中
        //   this.goodsSelected.push(index)
        // })
        //this.checkAllChecked = 'checked'
        this.cartLoading = false
      })
    },
    calSum(){
      let allNums = 0
      let allPrice = 0
      let _this = this
      this.goodsSelected.forEach(function (val){
        allNums = allNums + _this.cartList[val].qty
        allPrice = allPrice + _this.cartList[val].qty * _this.cartList[val].price
      });
      this.sumNums = allNums
      this.sumPrice = parseFloat(allPrice).toFixed(2)
    },
    submitCart(){
      this.submitLoading = true

      this.$store.commit('SET_CARTSELECTED', this.cartList.map(o => o.cartId))
      this.$router.push({path:'/cart/settlement'});
    },
    deleteCart(id){
      this.$confirm('此操作将删除购物车该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = [];
        data.push(id);
        cartDel({ids:data}).then(res => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.getCartList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    search(){
      let routerJump = this.$router.resolve({ path: '/search', query: { 'keywords':this.keywords } })
      window.open(routerJump.href, '_blank')
    },
    setCheckAll(event){
      if(!event.target.checked){
        this.checkAllChecked=false
        this.goodsSelected = []
      }else{
        let _this = this
        this.checkAllChecked=true
        this.goodsSelected = []
        this.cartList.forEach(function (val,index){
          _this.goodsSelected.push(index)
        })
      }
    },
    numChange(goods){
      this.cartLoading = true;

      updateCartNum({
        id:goods.cartId,
        goodsId:goods.goodsId,
        qty:goods.qty
      }).then(res => {
        this.$message({
          type: 'success',
          message: res.msg
        });
      }).finally(()=>{
         this.cartLoading = false
      })
    },
    batDel(){
      if(this.goodsSelected.length<=0){
        this.$message({
          type: 'info',
          message: '您未选中任何购物车商品！'
        });
        return
      }

      let _this = this
      this.$confirm('确认删除所选的购物车商品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = [];

        this.goodsSelected.forEach(function (val){
          data.push(_this.cartList[val].cartId);
        });

        cartDel({ids:data}).then(res => {
          this.$message({
            type: 'success',
            message: res.msg
          });
        })

      }).catch(() => {

      }).finally(()=>{
        this.getCartList()
      });
    },
    getAddress: function () {
      let _this = this;
      getAddressList().then(function(res){
        _this.consigneeInfo = res.data[0];
      }).catch(function (err) {
        console.log(err)
      });
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },
  }
}
</script>
<style>
.item-form .s-quantity .buy-num {
  width: 36px !important;
}
</style>
<style scoped>
.submit-btn{line-height: 0;border-radius: 0;}
.submit-btn:hover,.submit-btn:focus {background-color: #f42424;color: #fff;}
.item-form .s-quantity {width: 90px !important;padding: 0px 38px 0 0 !important;}
.item-form .s-goods{width: 375px;}
.item-form .s-props {width: 215px;}

.ck-order-remark{
  margin-top: 0;
  height: 52px;
}
.ck-order-remark .text{
  float: none;
  margin-left: 18px;
}
</style>
