<template>
  <div :class="cssName">
    <div class="ftit ftit-delr">
      <h3>猜你喜欢</h3>
    </div>
    <div class="gl-list clearfix">
      <ul class="clearfix">
        <li class="opacity_img" v-for="(good) in guesses" v-bind:key="good.id">
          <div class="p-img">
            <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
              <img :src="getCoverImage(good.images)" width="190" height="190">
            </router-link>
          </div>
          <div class="p-price">
            <em>¥</em>{{good.price}}
          </div>
          <div class="p-name">
            <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
              {{good.name}}
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {getGoodsSearch} from '@/api/good'

export default {
  name: "GuessLove",
  data() {
    return {
      guesses:[]
    }
  },
  props: {
    cssName: {
      type: String,
      default: 'p-panel-main guess-love'
    },
    count: {
      type: Number,
      default: 7
    }
  },
  created() {
    this.getGuesses()
  },
  methods:{
    getGuesses(){
      getGoodsSearch({
        currentPage: 1,
        pageSize: this.count,
        sidx: 'newid()',
      }).then(res => {
        this.guesses = res.data.list;
      })
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },
  }
}
</script>

<style scoped>

</style>
