import request from '@/utils/request'


export function getGoodsSearch(params){
    return request({
        url: '/app/mall/goods/search',
        method: 'post',
        needToken: false,
        data: params
    })
}

export function cartCreate(data) {
    return request({
        url: '/app/mall/cart',
        method: 'POST',
        needToken: true,
        data
    })
}



// export function getGoodsPageList(params){
//     return reqest({
//         url: '/api/app/mall/goods/GetGoodsPageList',
//         method: 'post',
//         needToken: false,
//         data: params
//     })
// }

// export function getCrumbsCategories(params){
//     return reqest({
//         url: '/api/Good/GetCrumbsCategories',
//         method: 'post',
//         needToken: false,
//         data: params
//     })
// }

// export function getGoodsRecommendList(params){
//     return reqest({
//         url: '/api/Good/GetGoodsRecommendList',
//         method: 'post',
//         needToken: false,
//         data: params
//     })
// }

export function getDetial(id){
    return request({
        url: `/app/mall/goods/${id}`,
        method: 'GET',
        needToken: false
    })
}

// export function getGoods(id) {
//     return request({
//         url: `/app/mall/goods/${id}`,
//         method: 'GET'
//     })
// }

// export function addCart(params){
//
//     // return request({
//     //     url: '/app/mall/cart',
//     //     method: 'POST',
//     //     data
//     // })
//
//     return request({
//         url: '/app/mall/cart',
//         method: 'POST',
//         needToken: true,
//         data: params
//     })
// }


