import request from '@/utils/request'

export function updateCartNum(data){
  return request({
    url: '/app/mall/cart/update',
    method: 'POST',
    needToken: true,
    data
  })
}

export function cartDel(data) {
  return request({
    url: `/app/mall/cart`,
    method: 'DELETE',
    needToken: true,
    data
  })
}

// export function deleteCart(data){
//   return request({
//     url: '/app/mall/cart',
//     method: 'DELETE',
//     needToken: true,
//     data
//   })
// }
